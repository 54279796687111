import { useNavigate } from "react-router-dom";
import { HomePath } from "../Pages/paths";

export default function Logo() {
    const navigate = useNavigate();
    return (
        <div
            className="w-[16rem] h-full flex mx-[3rem] cursor-pointer shrink-0"
            onClick={() => navigate(HomePath)}
        >
            <svg
                id="Layer_2"
                data-name="Layer 2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 439.77 53.92"
                strokeWidth="0"
                className=""
            >
                <g id="Layer_1-2" data-name="Layer 1">
                    <g>
                        <g>
                            <path
                                fill="#fff"
                                d="M46.41,51.5l-10.49-8.1c-2.74,1.45-5.87,2.28-9.19,2.28-10.87,0-19.68-8.81-19.68-19.68S15.87,6.32,26.74,6.32s19.43,8.58,19.67,19.24h.01v25.93Z"
                            />
                            <path
                                fill="#ff7518"
                                d="M53.42,25.08C52.56,11.09,40.95,0,26.74,0S0,11.97,0,26.74s11.09,25.83,25.08,26.68l-.05.05h28.45v-28.45l-.05.05ZM43.21,47.82l-8.78-6.78c-2.3,1.22-4.91,1.91-7.69,1.91-9.1,0-16.47-7.38-16.47-16.47s7.38-16.47,16.47-16.47,16.27,7.18,16.46,16.11h0v21.71Z"
                            />
                        </g>
                        <g>
                            <path
                                fill="#fff"
                                d="M85.87,21.69c7.75,0,13.81,6.18,13.81,16.06s-6.12,16.17-13.81,16.17c-4.61,0-7.92-2.08-9.66-4.83v4.38h-9.6V11.92h9.6v14.66c1.74-2.81,5.17-4.89,9.66-4.89ZM83,30.06c-3.59,0-6.85,2.75-6.85,7.75s3.26,7.75,6.85,7.75,6.91-2.81,6.91-7.81-3.26-7.69-6.91-7.69Z"
                            />
                            <path
                                fill="#fff"
                                d="M103.1,11.92h9.6v41.55h-9.6V11.92Z"
                            />
                            <path
                                fill="#fff"
                                d="M131.96,53.92c-9.21,0-15.83-6.18-15.83-16.12s6.51-16.12,15.83-16.12,15.61,6.06,15.61,15.61c0,.9-.06,1.85-.17,2.81h-21.73c.34,4.1,2.86,6.01,5.95,6.01,2.7,0,4.21-1.35,5-3.03h10.22c-1.52,6.12-7.08,10.84-14.88,10.84ZM125.73,34.77h12.02c0-3.43-2.7-5.39-5.9-5.39s-5.56,1.91-6.12,5.39Z"
                            />
                            <path
                                fill="#fff"
                                d="M172.62,36.46c0-4.27-2.36-6.63-6.01-6.63s-6.01,2.36-6.01,6.63v17.01h-9.6v-31.33h9.6v4.16c1.91-2.64,5.28-4.49,9.49-4.49,7.24,0,12.07,4.94,12.07,13.36v18.31h-9.55v-17.01Z"
                            />
                            <path
                                fill="#fff"
                                d="M199.12,21.69c4.16,0,7.75,1.91,9.66,4.77v-14.54h9.6v41.55h-9.6v-4.49c-1.74,2.86-5.05,4.94-9.66,4.94-7.69,0-13.81-6.29-13.81-16.17s6.12-16.06,13.81-16.06ZM201.92,30.06c-3.59,0-6.85,2.7-6.85,7.69s3.26,7.81,6.85,7.81,6.85-2.75,6.85-7.75-3.2-7.75-6.85-7.75Z"
                            />
                            <path
                                fill="#fff"
                                d="M237.63,53.92c-9.21,0-15.83-6.18-15.83-16.12s6.51-16.12,15.83-16.12,15.61,6.06,15.61,15.61c0,.9-.06,1.85-.17,2.81h-21.73c.34,4.1,2.86,6.01,5.95,6.01,2.7,0,4.21-1.35,5-3.03h10.22c-1.52,6.12-7.07,10.84-14.88,10.84ZM231.4,34.77h12.02c0-3.43-2.7-5.39-5.9-5.39s-5.56,1.91-6.12,5.39Z"
                            />
                            <path
                                fill="#fff"
                                d="M266.27,53.47h-9.6v-31.33h9.6v5.22c2.19-3.31,5.62-5.56,9.83-5.56v10.16h-2.64c-4.55,0-7.19,1.57-7.19,6.96v14.54Z"
                            />
                            <path
                                fill="#fff"
                                d="M298.33,21.69c7.75,0,13.81,6.18,13.81,16.06s-6.12,16.17-13.81,16.17c-4.61,0-7.92-2.08-9.66-4.83v4.38h-9.6V11.92h9.6v14.66c1.74-2.81,5.17-4.89,9.66-4.89ZM295.47,30.06c-3.59,0-6.85,2.75-6.85,7.75s3.26,7.75,6.85,7.75,6.91-2.81,6.91-7.81-3.26-7.69-6.91-7.69Z"
                            />
                            <path
                                fill="#fff"
                                d="M329.83,53.92c-9.21,0-16.17-6.18-16.17-16.12s7.13-16.12,16.28-16.12,16.28,6.18,16.28,16.12-7.19,16.12-16.4,16.12ZM329.83,45.61c3.43,0,6.63-2.53,6.63-7.81s-3.14-7.81-6.51-7.81-6.51,2.47-6.51,7.81,2.92,7.81,6.4,7.81Z"
                            />
                            <path
                                fill="#fff"
                                d="M361.61,21.69c4.66,0,7.97,2.13,9.66,4.89v-4.44h9.6v31.33h-9.6v-4.44c-1.74,2.75-5.05,4.89-9.71,4.89-7.64,0-13.76-6.29-13.76-16.17s6.12-16.06,13.81-16.06ZM364.42,30.06c-3.59,0-6.85,2.7-6.85,7.69s3.26,7.81,6.85,7.81,6.85-2.75,6.85-7.75-3.2-7.75-6.85-7.75Z"
                            />
                            <path
                                fill="#fff"
                                d="M395.8,53.47h-9.6v-31.33h9.6v5.22c2.19-3.31,5.62-5.56,9.83-5.56v10.16h-2.64c-4.55,0-7.19,1.57-7.19,6.96v14.54Z"
                            />
                            <path
                                fill="#fff"
                                d="M420.51,21.69c4.16,0,7.75,1.91,9.66,4.77v-14.54h9.6v41.55h-9.6v-4.49c-1.74,2.86-5.05,4.94-9.66,4.94-7.69,0-13.81-6.29-13.81-16.17s6.12-16.06,13.81-16.06ZM423.31,30.06c-3.59,0-6.85,2.7-6.85,7.69s3.26,7.81,6.85,7.81,6.85-2.75,6.85-7.75-3.2-7.75-6.85-7.75Z"
                            />
                        </g>
                        <circle fill="#265787" cx="26.69" cy="26.74" r="6.65" />
                    </g>
                </g>
            </svg>
        </div>
    );
}
